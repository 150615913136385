<script setup lang="ts">
defineProps<{
    link: string;
    locale: string;
}>();

const config = useConfig();

const runtimeConfig = useRuntimeConfig();
const homeLink = runtimeConfig.public.homeUrl;
</script>

<template>
    <nuxt-link v-if="locale === config.locale" :to="link" :class="$attrs.class">
        <slot></slot>
    </nuxt-link>
    <a v-else-if="locale === 'external'" :href="link" target="_blank" rel="noopener" :class="$attrs.class">
        <slot></slot>
    </a>
    <a v-else :href="(locale === '_' || $locales[locale] === undefined ? homeLink : $locales[locale].url) + link" :class="$attrs.class">
        <slot></slot>
    </a>
</template>
